import React, { FC, memo } from 'react';
import { graphql } from 'gatsby';

import { ITemplateProps } from 'src/interfaces/template';
import HomeTemplate from 'src/components/templates/home/HomeTemplate';

interface PropsType extends ITemplateProps<unknown> {}

const IndexPage: FC<PropsType> = memo(({ data }) => {
  return <HomeTemplate data={data as GatsbyTypes.Query} />;
});

export default IndexPage;

export const pageQuery = graphql`
  query LatestPostListQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 10
      sort: { order: DESC, fields: frontmatter___date }
      filter: { frontmatter: { published: { eq: true } } }
    ) {
      edges {
        node {
          excerpt(truncate: true, pruneLength: 300)
          frontmatter {
            title
            date
            slug
            tags
            series
            comments
            published
            cover {
              id
              absolutePath
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          id
        }
      }
    }
  }
`;
