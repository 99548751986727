import React, { FC } from 'react';

import SEO from 'src/components/organisms/common/SEO';
import PostList from 'src/components/organisms/post/PostList';
import Layout from 'src/components/templates/layout/Layout';
import usePosts from 'src/hooks/usePosts';

interface PropsType {
  data: GatsbyTypes.Query;
}

const HomeTemplate: FC<PropsType> = ({ data }) => {
  const title = data.site.siteMetadata.title;
  const posts = usePosts(data.allMarkdownRemark);

  return (
    <Layout>
      <SEO title={title} />
      <PostList posts={posts} />
    </Layout>
  );
};

export default HomeTemplate;
